<template>
  <v-select
    v-model="_selected"
    :items="items"
    hide-details
    single-line
    solo
    flat
    outlined
    :menu-props="menuProps"
    append-icon=""
    :class="{
      'solo-select-align-right': alignRight,
      'solo-select-align-center': alignCenter,
      'solo-select-align-left': alignLeft,
      tablet: _isTablet,
    }"
  >
    <template v-slot:selection="{ item }" v-if="useSelectionSlot">
      <slot name="soloSelectSelection" :item="item"></slot>
    </template>
  </v-select>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default() {
          return [];
        },
      },
      selected: {
        type: [String, Number],
        default: "",
      },
      alignLeft: {
        type: Boolean,
        default: false,
      },
      alignRight: {
        type: Boolean,
        default: false,
      },
      alignCenter: {
        type: Boolean,
        default: false,
      },
      menuProps: {
        type: [String, Array, Object],
        default: "",
      },
      useSelectionSlot: {
        type: Boolean,
        default: false,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _selected: {
        get() {
          return this.selected;
        },
        set(value) {
          this.$emit("update:selected", value);
        },
      },
      _isTablet: {
        get() {
          return this.tablet;
        },
      },
    },
  };
</script>

<style scoped>
  .tablet >>> .v-list-item .v-list-item__title,
  .tablet >>> .v-list-item .v-list-item__subtitle {
    line-height: 1.9rem;
  }

  .tablet >>> .v-select__selection.v-select__selection--comma {
    margin-right: 0px;
  }

  >>> .v-list-item .v-list-item__title {
    font-size: 1.9rem;
    line-height: 1.9rem;
  }

  >>> .v-select__selections {
    padding-top: 3px;
    font-size: 1.9rem;
    line-height: 1.9rem;
  }

  .solo-select-align-right >>> .v-select__selection {
    max-width: 100%;
    width: 100%;
    text-align: right;
  }

  .solo-select-align-center >>> .v-select__selection {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .solo-select-align-left >>> .v-select__selection {
    max-width: 100%;
    width: 100%;
    text-align: left;
  }
</style>
