var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{class:{
    'solo-select-align-right': _vm.alignRight,
    'solo-select-align-center': _vm.alignCenter,
    'solo-select-align-left': _vm.alignLeft,
    tablet: _vm._isTablet,
  },attrs:{"items":_vm.items,"hide-details":"","single-line":"","solo":"","flat":"","outlined":"","menu-props":_vm.menuProps,"append-icon":""},scopedSlots:_vm._u([(_vm.useSelectionSlot)?{key:"selection",fn:function(ref){
  var item = ref.item;
return [_vm._t("soloSelectSelection",null,{"item":item})]}}:null],null,true),model:{value:(_vm._selected),callback:function ($$v) {_vm._selected=$$v},expression:"_selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }