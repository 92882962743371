import {
    OVERTIME_TYPE
    , VACATION_TYPE
    , VACATION_TERM_TYPE
    , VACATION_TERM_HALF_TYPE
    , DEPARTMENT
    , STAY
    , SITE_FILTER_TYPE
    , REQUEST_TYPE
    , SUSPEND_WORK_TYPE
    , BREAK_TIME_WORK
    , AUTHORIZER_FILTER_TYPE
    , EMPLOYEE_CLASS_TYPE
    , REQUEST_APPROVAL_AUTHORITY_TYPE
    , REQUEST_TARGET_TYPE
    , EMPLOYEE_AUTHORITY_TYPE
} from "./const.js"


export const OVERTIME_TYPE_TABLE = [
    Object.freeze({ value: OVERTIME_TYPE.TYPE.OVERTIME, text: OVERTIME_TYPE.NAME.OVERTIME }),
    Object.freeze({ value: OVERTIME_TYPE.TYPE.HOLIDAY_WORK, text: OVERTIME_TYPE.NAME.HOLIDAY_WORK }),
]

export const VACATION_TYPE_TABLE = [
    Object.freeze({ value: VACATION_TYPE.TYPE.ANNUAL_VACATION, text: VACATION_TYPE.NAME.ANNUAL_VACATION }),
    Object.freeze({ value: VACATION_TYPE.TYPE.CONDOLENCE_VACATION, text: VACATION_TYPE.NAME.CONDOLENCE_VACATION }),
    Object.freeze({ value: VACATION_TYPE.TYPE.CHILDCARE_VACATION, text: VACATION_TYPE.NAME.CHILDCARE_VACATION }),
    Object.freeze({ value: VACATION_TYPE.TYPE.NURSING_CARE_VACATION, text: VACATION_TYPE.NAME.NURSING_CARE_VACATION }),
    Object.freeze({ value: VACATION_TYPE.TYPE.ALTERNATIVE_VACATION, text: VACATION_TYPE.NAME.ALTERNATIVE_VACATION }),
    Object.freeze({ value: VACATION_TYPE.TYPE.PAID_HOLIDAYS, text: VACATION_TYPE.NAME.PAID_HOLIDAYS }),
    Object.freeze({ value: VACATION_TYPE.TYPE.UNPAID_HOLIDAYS, text: VACATION_TYPE.NAME.UNPAID_HOLIDAYS }),
]

export const VACATION_TERM_TYPE_TABLE = [
    Object.freeze({ value: VACATION_TERM_TYPE.TYPE.ONE_DAY, text: VACATION_TERM_TYPE.NAME.ONE_DAY }),
    Object.freeze({ value: VACATION_TERM_TYPE.TYPE.HALF_DAY, text: VACATION_TERM_TYPE.NAME.HALF_DAY }),
    Object.freeze({ value: VACATION_TERM_TYPE.TYPE.TERM, text: VACATION_TERM_TYPE.NAME.TERM }),
]

export const VACATION_TERM_HALF_TYPE_TABLE = [
    Object.freeze({ value: VACATION_TERM_HALF_TYPE.TYPE.AM, text: VACATION_TERM_HALF_TYPE.NAME.AM }),
    Object.freeze({ value: VACATION_TERM_HALF_TYPE.TYPE.PM, text: VACATION_TERM_HALF_TYPE.NAME.PM }),
]

export const DEPARTMENT_TABLE = [
    Object.freeze({ value: DEPARTMENT.TYPE.DOBOKU, text: DEPARTMENT.NAME.DOBOKU }),
    Object.freeze({ value: DEPARTMENT.TYPE.KENTIKU, text: DEPARTMENT.NAME.KENTIKU }),
    Object.freeze({ value: DEPARTMENT.TYPE.KANKYO, text: DEPARTMENT.NAME.KANKYO }),
    Object.freeze({ value: DEPARTMENT.TYPE.OTHER, text: DEPARTMENT.NAME.OTHER }),
]

export const STAY_TABLE = [
    Object.freeze({ value: STAY.TYPE.NONE, text: STAY.NAME.NONE }),
    Object.freeze({ value: STAY.TYPE.KENNAI, text: STAY.NAME.KENNAI }),
    Object.freeze({ value: STAY.TYPE.KENGAI, text: STAY.NAME.KENGAI }),
    Object.freeze({ value: STAY.TYPE.RITO, text: STAY.NAME.RITO }),
]

export const SITE_FILTER_TYPE_TABLE = [
    Object.freeze({ value: SITE_FILTER_TYPE.TYPE.SITE_NAME, text: SITE_FILTER_TYPE.NAME.SITE_NAME }),
    Object.freeze({ value: SITE_FILTER_TYPE.TYPE.SITE_TANTOU, text: SITE_FILTER_TYPE.NAME.SITE_TANTOU }),
    Object.freeze({ value: SITE_FILTER_TYPE.TYPE.BUSHO, text: SITE_FILTER_TYPE.NAME.BUSHO }),
]

export const REQUEST_TYPE_TABLE = [
    Object.freeze({ value: REQUEST_TYPE.TYPE.OVERTIME, text: REQUEST_TYPE.NAME.OVERTIME }),
    Object.freeze({ value: REQUEST_TYPE.TYPE.VACATION, text: REQUEST_TYPE.NAME.VACATION }),
]

export const SUSPEND_WORK_TYPE_TABLE = [
    Object.freeze({ value: SUSPEND_WORK_TYPE.TYPE.NORMAL, text: SUSPEND_WORK_TYPE.NAME.NORMAL }),
    Object.freeze({ value: SUSPEND_WORK_TYPE.TYPE.PART, text: SUSPEND_WORK_TYPE.NAME.PART }),
    Object.freeze({ value: SUSPEND_WORK_TYPE.TYPE.ALL, text: SUSPEND_WORK_TYPE.NAME.ALL }),
    Object.freeze({ value: SUSPEND_WORK_TYPE.TYPE.AFTER_NIGHT_SHIFT, text: SUSPEND_WORK_TYPE.NAME.AFTER_NIGHT_SHIFT }),
]

export const BREAK_TIME_WORK_TABLE = [
    Object.freeze({ value: BREAK_TIME_WORK.TYPE.NONE, text: BREAK_TIME_WORK.NAME.NONE }),
    Object.freeze({ value: BREAK_TIME_WORK.TYPE.QUARTER, text: BREAK_TIME_WORK.NAME.QUARTER }),
    Object.freeze({ value: BREAK_TIME_WORK.TYPE.HALF, text: BREAK_TIME_WORK.NAME.HALF }),
    Object.freeze({ value: BREAK_TIME_WORK.TYPE.THREEQUARTER, text: BREAK_TIME_WORK.NAME.THREEQUARTER }),
    Object.freeze({ value: BREAK_TIME_WORK.TYPE.HOUR, text: BREAK_TIME_WORK.NAME.HOUR }),
    Object.freeze({ value: BREAK_TIME_WORK.TYPE.HOURQUARTER, text: BREAK_TIME_WORK.NAME.HOURQUARTER }),
    Object.freeze({ value: BREAK_TIME_WORK.TYPE.HOURHALF, text: BREAK_TIME_WORK.NAME.HOURHALF }),
]

export const AUTHORIZER_FILTER_TYPE_TABLE = [
    Object.freeze({ value: AUTHORIZER_FILTER_TYPE.TYPE.NAME, text: AUTHORIZER_FILTER_TYPE.NAME.NAME }),
    Object.freeze({ value: AUTHORIZER_FILTER_TYPE.TYPE.SITE, text: AUTHORIZER_FILTER_TYPE.NAME.SITE }),
    Object.freeze({ value: AUTHORIZER_FILTER_TYPE.TYPE.BUSHO, text: AUTHORIZER_FILTER_TYPE.NAME.BUSHO }),
]

export const EMPLOYEE_CLASS_TYPE_TABLE = [
    Object.freeze({ value: EMPLOYEE_CLASS_TYPE.TYPE.A, text: EMPLOYEE_CLASS_TYPE.NAME.A }),
    Object.freeze({ value: EMPLOYEE_CLASS_TYPE.TYPE.B, text: EMPLOYEE_CLASS_TYPE.NAME.B }),
    Object.freeze({ value: EMPLOYEE_CLASS_TYPE.TYPE.C, text: EMPLOYEE_CLASS_TYPE.NAME.C }),
    Object.freeze({ value: EMPLOYEE_CLASS_TYPE.TYPE.D, text: EMPLOYEE_CLASS_TYPE.NAME.D }),
]

export const REQUEST_APPROVAL_AUTHORITY_TYPE_TABLE = [
    Object.freeze({ value: REQUEST_APPROVAL_AUTHORITY_TYPE.TYPE.NONE, text: REQUEST_APPROVAL_AUTHORITY_TYPE.NAME.NONE }),
    Object.freeze({ value: REQUEST_APPROVAL_AUTHORITY_TYPE.TYPE.APPROVAL, text: REQUEST_APPROVAL_AUTHORITY_TYPE.NAME.APPROVAL }),
    Object.freeze({ value: REQUEST_APPROVAL_AUTHORITY_TYPE.TYPE.NOTICE, text: REQUEST_APPROVAL_AUTHORITY_TYPE.NAME.NOTICE }),
]

export const EMPLOYEE_AUTHORITY_TYPE_TABLE = [
    Object.freeze({ value: EMPLOYEE_AUTHORITY_TYPE.TYPE.NONE, text: EMPLOYEE_AUTHORITY_TYPE.NAME.NONE }),
    Object.freeze({ value: EMPLOYEE_AUTHORITY_TYPE.TYPE.SYSTEM_MANAGER, text: EMPLOYEE_AUTHORITY_TYPE.NAME.SYSTEM_MANAGER }),
]

export const FIXED_OVERTIME_HOUR_TABLE = [
    Object.freeze({ value: 0, text: "0H" }),
    Object.freeze({ value: 20, text: "20H" }),
    Object.freeze({ value: 40, text: "40H" }),
]

export const VACATION_REASON_TABLE = [
    Object.freeze({ value: '1', text: '私用' }),
    Object.freeze({ value: '2', text: '病気・療養' }),
    Object.freeze({ value: '3', text: '帰省' }),
    Object.freeze({ value: '4', text: '慶事' }),
    Object.freeze({ value: '5', text: '弔事' }),
    Object.freeze({ value: '6', text: '介護' }),
    Object.freeze({ value: '7', text: '育児' }),
    Object.freeze({ value: '8', text: '代替' }),
    Object.freeze({ value: '9', text: 'その他' }),
]

export const TARGET_TABLE = [
    Object.freeze({ value: 1, text: '対象' }),
    Object.freeze({ value: 0, text: '対象外' }),
]

export const SEARCH_INITIAL_TABLE_1 = [
    Object.freeze({ value: "あ", text: "あ", }),
    Object.freeze({ value: "か", text: "か", }),
    Object.freeze({ value: "さ", text: "さ", }),
    Object.freeze({ value: "た", text: "た", }),
    Object.freeze({ value: "な", text: "な", }),
    Object.freeze({ value: "は", text: "は", }),
    Object.freeze({ value: "ま", text: "ま", }),
    Object.freeze({ value: "や", text: "や", }),
    Object.freeze({ value: "ら", text: "ら", }),
    Object.freeze({ value: "わ", text: "わ", }),
]

export const SEARCH_INITIAL_TABLE_2 = Object.freeze({
    "あ": [
        Object.freeze({ value: "あ", text: "あ", items: ["あ"] }),
        Object.freeze({ value: "い", text: "い", items: ["い"] }),
        Object.freeze({ value: "う", text: "う", items: ["う"] }),
        Object.freeze({ value: "え", text: "え", items: ["え"] }),
        Object.freeze({ value: "お", text: "お", items: ["お"] }),
    ],
    "か": [
        Object.freeze({ value: "か", text: "か", items: ["か", "が"] }),
        Object.freeze({ value: "き", text: "き", items: ["き", "ぎ"] }),
        Object.freeze({ value: "く", text: "く", items: ["く", "ぐ"] }),
        Object.freeze({ value: "け", text: "け", items: ["け", "げ"] }),
        Object.freeze({ value: "こ", text: "こ", items: ["こ", "ご"] }),
    ],
    "さ": [
        Object.freeze({ value: "さ", text: "さ", items: ["さ", "ざ"] }),
        Object.freeze({ value: "し", text: "し", items: ["し", "じ"] }),
        Object.freeze({ value: "す", text: "す", items: ["す", "ず"] }),
        Object.freeze({ value: "せ", text: "せ", items: ["せ", "ぜ"] }),
        Object.freeze({ value: "そ", text: "そ", items: ["そ", "ぞ"] }),
    ],
    "た": [
        Object.freeze({ value: "た", text: "た", items: ["た", "だ"] }),
        Object.freeze({ value: "ち", text: "ち", items: ["ち", "ぢ"] }),
        Object.freeze({ value: "つ", text: "つ", items: ["つ", "づ"] }),
        Object.freeze({ value: "て", text: "て", items: ["て", "で"] }),
        Object.freeze({ value: "と", text: "と", items: ["と", "ど"] }),
    ],
    "な": [
        Object.freeze({ value: "な", text: "な", items: ["な"] }),
        Object.freeze({ value: "に", text: "に", items: ["に"] }),
        Object.freeze({ value: "ぬ", text: "ぬ", items: ["ぬ"] }),
        Object.freeze({ value: "ね", text: "ね", items: ["ね"] }),
        Object.freeze({ value: "の", text: "の", items: ["の"] }),
    ],
    "は": [
        Object.freeze({ value: "は", text: "は", items: ["は", "ば", "ぱ"] }),
        Object.freeze({ value: "ひ", text: "ひ", items: ["ひ", "び", "ぴ"] }),
        Object.freeze({ value: "ふ", text: "ふ", items: ["ふ", "ぶ", "ぷ"] }),
        Object.freeze({ value: "へ", text: "へ", items: ["へ", "べ", "ぺ"] }),
        Object.freeze({ value: "ほ", text: "ほ", items: ["ほ", "ぼ", "ぽ"] }),
    ],
    "ま": [
        Object.freeze({ value: "ま", text: "ま", items: ["ま"] }),
        Object.freeze({ value: "み", text: "み", items: ["み"] }),
        Object.freeze({ value: "む", text: "む", items: ["む"] }),
        Object.freeze({ value: "め", text: "め", items: ["め"] }),
        Object.freeze({ value: "も", text: "も", items: ["も"] }),
    ],
    "や": [
        Object.freeze({ value: "や", text: "や", items: ["や"] }),
        Object.freeze({ value: "ゆ", text: "ゆ", items: ["ゆ"] }),
        Object.freeze({ value: "よ", text: "よ", items: ["よ"] }),
    ],
    "ら": [
        Object.freeze({ value: "ら", text: "ら", items: ["ら"] }),
        Object.freeze({ value: "り", text: "り", items: ["り"] }),
        Object.freeze({ value: "る", text: "る", items: ["る"] }),
        Object.freeze({ value: "れ", text: "れ", items: ["れ"] }),
        Object.freeze({ value: "ろ", text: "ろ", items: ["ろ"] }),
    ],
    "わ": [
        Object.freeze({ value: "わ", text: "わ", items: ["わ"] }),
        Object.freeze({ value: "を", text: "を", items: ["を"] }),
        Object.freeze({ value: "ん", text: "ん", items: ["ん"] }),
    ]
})

export const MONTH_TABLE = [
    Object.freeze({ value: 1, text: "1", }),
    Object.freeze({ value: 2, text: "2", }),
    Object.freeze({ value: 3, text: "3", }),
    Object.freeze({ value: 4, text: "4", }),
    Object.freeze({ value: 5, text: "5", }),
    Object.freeze({ value: 6, text: "6", }),
    Object.freeze({ value: 7, text: "7", }),
    Object.freeze({ value: 8, text: "8", }),
    Object.freeze({ value: 9, text: "9", }),
    Object.freeze({ value: 10, text: "10", }),
    Object.freeze({ value: 11, text: "11", }),
    Object.freeze({ value: 12, text: "12", }),
]

export const DAY28_TABLE = [
    Object.freeze({ value: 1, text: "1", }),
    Object.freeze({ value: 2, text: "2", }),
    Object.freeze({ value: 3, text: "3", }),
    Object.freeze({ value: 4, text: "4", }),
    Object.freeze({ value: 5, text: "5", }),
    Object.freeze({ value: 6, text: "6", }),
    Object.freeze({ value: 7, text: "7", }),
    Object.freeze({ value: 8, text: "8", }),
    Object.freeze({ value: 9, text: "9", }),
    Object.freeze({ value: 10, text: "10", }),
    Object.freeze({ value: 11, text: "11", }),
    Object.freeze({ value: 12, text: "12", }),
    Object.freeze({ value: 13, text: "13", }),
    Object.freeze({ value: 14, text: "14", }),
    Object.freeze({ value: 15, text: "15", }),
    Object.freeze({ value: 16, text: "16", }),
    Object.freeze({ value: 17, text: "17", }),
    Object.freeze({ value: 18, text: "18", }),
    Object.freeze({ value: 19, text: "19", }),
    Object.freeze({ value: 20, text: "20", }),
    Object.freeze({ value: 21, text: "21", }),
    Object.freeze({ value: 22, text: "22", }),
    Object.freeze({ value: 23, text: "23", }),
    Object.freeze({ value: 24, text: "24", }),
    Object.freeze({ value: 25, text: "25", }),
    Object.freeze({ value: 26, text: "26", }),
    Object.freeze({ value: 27, text: "27", }),
    Object.freeze({ value: 28, text: "28", }),
]

export const DAY29_TABLE = [
    Object.freeze({ value: 1, text: "1", }),
    Object.freeze({ value: 2, text: "2", }),
    Object.freeze({ value: 3, text: "3", }),
    Object.freeze({ value: 4, text: "4", }),
    Object.freeze({ value: 5, text: "5", }),
    Object.freeze({ value: 6, text: "6", }),
    Object.freeze({ value: 7, text: "7", }),
    Object.freeze({ value: 8, text: "8", }),
    Object.freeze({ value: 9, text: "9", }),
    Object.freeze({ value: 10, text: "10", }),
    Object.freeze({ value: 11, text: "11", }),
    Object.freeze({ value: 12, text: "12", }),
    Object.freeze({ value: 13, text: "13", }),
    Object.freeze({ value: 14, text: "14", }),
    Object.freeze({ value: 15, text: "15", }),
    Object.freeze({ value: 16, text: "16", }),
    Object.freeze({ value: 17, text: "17", }),
    Object.freeze({ value: 18, text: "18", }),
    Object.freeze({ value: 19, text: "19", }),
    Object.freeze({ value: 20, text: "20", }),
    Object.freeze({ value: 21, text: "21", }),
    Object.freeze({ value: 22, text: "22", }),
    Object.freeze({ value: 23, text: "23", }),
    Object.freeze({ value: 24, text: "24", }),
    Object.freeze({ value: 25, text: "25", }),
    Object.freeze({ value: 26, text: "26", }),
    Object.freeze({ value: 27, text: "27", }),
    Object.freeze({ value: 28, text: "28", }),
    Object.freeze({ value: 29, text: "29", }),
]

export const DAY30_TABLE = [
    Object.freeze({ value: 1, text: "1", }),
    Object.freeze({ value: 2, text: "2", }),
    Object.freeze({ value: 3, text: "3", }),
    Object.freeze({ value: 4, text: "4", }),
    Object.freeze({ value: 5, text: "5", }),
    Object.freeze({ value: 6, text: "6", }),
    Object.freeze({ value: 7, text: "7", }),
    Object.freeze({ value: 8, text: "8", }),
    Object.freeze({ value: 9, text: "9", }),
    Object.freeze({ value: 10, text: "10", }),
    Object.freeze({ value: 11, text: "11", }),
    Object.freeze({ value: 12, text: "12", }),
    Object.freeze({ value: 13, text: "13", }),
    Object.freeze({ value: 14, text: "14", }),
    Object.freeze({ value: 15, text: "15", }),
    Object.freeze({ value: 16, text: "16", }),
    Object.freeze({ value: 17, text: "17", }),
    Object.freeze({ value: 18, text: "18", }),
    Object.freeze({ value: 19, text: "19", }),
    Object.freeze({ value: 20, text: "20", }),
    Object.freeze({ value: 21, text: "21", }),
    Object.freeze({ value: 22, text: "22", }),
    Object.freeze({ value: 23, text: "23", }),
    Object.freeze({ value: 24, text: "24", }),
    Object.freeze({ value: 25, text: "25", }),
    Object.freeze({ value: 26, text: "26", }),
    Object.freeze({ value: 27, text: "27", }),
    Object.freeze({ value: 28, text: "28", }),
    Object.freeze({ value: 29, text: "29", }),
    Object.freeze({ value: 30, text: "30", }),
]

export const DAY31_TABLE = [
    Object.freeze({ value: 1, text: "1", }),
    Object.freeze({ value: 2, text: "2", }),
    Object.freeze({ value: 3, text: "3", }),
    Object.freeze({ value: 4, text: "4", }),
    Object.freeze({ value: 5, text: "5", }),
    Object.freeze({ value: 6, text: "6", }),
    Object.freeze({ value: 7, text: "7", }),
    Object.freeze({ value: 8, text: "8", }),
    Object.freeze({ value: 9, text: "9", }),
    Object.freeze({ value: 10, text: "10", }),
    Object.freeze({ value: 11, text: "11", }),
    Object.freeze({ value: 12, text: "12", }),
    Object.freeze({ value: 13, text: "13", }),
    Object.freeze({ value: 14, text: "14", }),
    Object.freeze({ value: 15, text: "15", }),
    Object.freeze({ value: 16, text: "16", }),
    Object.freeze({ value: 17, text: "17", }),
    Object.freeze({ value: 18, text: "18", }),
    Object.freeze({ value: 19, text: "19", }),
    Object.freeze({ value: 20, text: "20", }),
    Object.freeze({ value: 21, text: "21", }),
    Object.freeze({ value: 22, text: "22", }),
    Object.freeze({ value: 23, text: "23", }),
    Object.freeze({ value: 24, text: "24", }),
    Object.freeze({ value: 25, text: "25", }),
    Object.freeze({ value: 26, text: "26", }),
    Object.freeze({ value: 27, text: "27", }),
    Object.freeze({ value: 28, text: "28", }),
    Object.freeze({ value: 29, text: "29", }),
    Object.freeze({ value: 30, text: "30", }),
    Object.freeze({ value: 31, text: "31", }),
]

export const MINUTE_TABLE = [
    Object.freeze({ value: 0, text: "00", }),
    Object.freeze({ value: 1, text: "01", }),
    Object.freeze({ value: 2, text: "02", }),
    Object.freeze({ value: 3, text: "03", }),
    Object.freeze({ value: 4, text: "04", }),
    Object.freeze({ value: 5, text: "05", }),
    Object.freeze({ value: 6, text: "06", }),
    Object.freeze({ value: 7, text: "07", }),
    Object.freeze({ value: 8, text: "08", }),
    Object.freeze({ value: 9, text: "09", }),
    Object.freeze({ value: 10, text: "10", }),
    Object.freeze({ value: 11, text: "11", }),
    Object.freeze({ value: 12, text: "12", }),
    Object.freeze({ value: 13, text: "13", }),
    Object.freeze({ value: 14, text: "14", }),
    Object.freeze({ value: 15, text: "15", }),
    Object.freeze({ value: 16, text: "16", }),
    Object.freeze({ value: 17, text: "17", }),
    Object.freeze({ value: 18, text: "18", }),
    Object.freeze({ value: 19, text: "19", }),
    Object.freeze({ value: 20, text: "20", }),
    Object.freeze({ value: 21, text: "21", }),
    Object.freeze({ value: 22, text: "22", }),
    Object.freeze({ value: 23, text: "23", }),
    Object.freeze({ value: 24, text: "24", }),
    Object.freeze({ value: 25, text: "25", }),
    Object.freeze({ value: 26, text: "26", }),
    Object.freeze({ value: 27, text: "27", }),
    Object.freeze({ value: 28, text: "28", }),
    Object.freeze({ value: 29, text: "29", }),
    Object.freeze({ value: 30, text: "30", }),
    Object.freeze({ value: 31, text: "31", }),
    Object.freeze({ value: 32, text: "32", }),
    Object.freeze({ value: 33, text: "33", }),
    Object.freeze({ value: 34, text: "34", }),
    Object.freeze({ value: 35, text: "35", }),
    Object.freeze({ value: 36, text: "36", }),
    Object.freeze({ value: 37, text: "37", }),
    Object.freeze({ value: 38, text: "38", }),
    Object.freeze({ value: 39, text: "39", }),
    Object.freeze({ value: 40, text: "40", }),
    Object.freeze({ value: 41, text: "41", }),
    Object.freeze({ value: 42, text: "42", }),
    Object.freeze({ value: 43, text: "43", }),
    Object.freeze({ value: 44, text: "44", }),
    Object.freeze({ value: 45, text: "45", }),
    Object.freeze({ value: 46, text: "46", }),
    Object.freeze({ value: 47, text: "47", }),
    Object.freeze({ value: 48, text: "48", }),
    Object.freeze({ value: 49, text: "49", }),
    Object.freeze({ value: 50, text: "50", }),
    Object.freeze({ value: 51, text: "51", }),
    Object.freeze({ value: 52, text: "52", }),
    Object.freeze({ value: 53, text: "53", }),
    Object.freeze({ value: 54, text: "54", }),
    Object.freeze({ value: 55, text: "55", }),
    Object.freeze({ value: 56, text: "56", }),
    Object.freeze({ value: 57, text: "57", }),
    Object.freeze({ value: 58, text: "58", }),
    Object.freeze({ value: 59, text: "59", }),
]

export const LOGIN_TYPE_TABLE = [
    Object.freeze({ value: 0, text: '通常ログイン' }),
    Object.freeze({ value: 1, text: '代理ログイン' }),
]

export const OPERATION_TYPE_TABLE = [
    Object.freeze({ value: 0, text: '利用不可' }),
    Object.freeze({ value: 1, text: '閲覧のみ' }),
    Object.freeze({ value: 2, text: 'データ操作（登録・編集・削除）' }),
]

export default {
    getText(table, value) {
        let text = "";
        let rec = table.find(
            (item) => item.value === value
        );
        if (rec !== undefined) {
            text = rec.text;
        }
        return text;
    },
    getInitialItems(initial, value) {
        let items = [];
        if (SEARCH_INITIAL_TABLE_2[initial]) {
            let findItems = SEARCH_INITIAL_TABLE_2[initial].find(item => item.value === value);
            if (findItems) {
                items = findItems.items;
            }
        }
        return items;
    }
}
